import type { Asset, Entry } from 'contentful';
import type { GetServerSideProps, GetServerSidePropsResult, Redirect } from 'next';
import * as Sentry from '@sentry/nextjs';
import { ServerResponse, IncomingMessage } from 'http';
import {
  IEcommIds,
  IFragmentSocialMediaLinks,
  INavFooterFields,
  INavHeaderFields,
  ISiteFields,
  IMetaCordial,
  IFragmentSubscriptionLightbox,
} from 'types/contentful';
import cmsClient from './cms/client';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import Cookies from 'cookies';
import { v4 as uuid } from 'uuid';
import { DUMMY_URL_BASE } from './product';
import { removeIfUndefined } from './util';
import { Document } from '@contentful/rich-text-types';

export interface PageDates {
  datePublished: string;
  dateModified: string;
}

export interface BrandColors {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  highlightColor: string;
  mutedColor: string;
  neutralColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  tertiaryTextColor: string;
  highlightTextColor: string;
  mutedTextColor: string;
  neutralTextColor: string;
}

export interface BrandedSiteProps {
  siteName: string;
  topNav: INavHeaderFields;
  footerNav: INavFooterFields;
  domain: string;
  logo: Asset;
  favicon: Asset | null;
  googleTagManagerId?: string | null;
  freshPaintToken?: string | null;
  gaMeasurementIdOrUATrackingId?: string | null;
  optimizelyProjectId?: string | null;
  socialMediaLink?: IFragmentSocialMediaLinks[] | null;
  ecommIds: IEcommIds | null;
  cordialMetaData?: IMetaCordial | null;
  branding: BrandColors;
  reviewStatement: Document | null;
  showLocationFilterFeature: boolean | null;
  subscriptionLightbox: IFragmentSubscriptionLightbox | null;
  profitCenter: string | null;
  metaPhoneNumber?: [string] | [] | null;
}

export class BadRequestError extends Error {
  httpCode: number;

  constructor(message: string) {
    super(message);
    this.name = 'BadRequestError';
    this.httpCode = 400;
  }
}

export function getBrandedSiteData(site: Entry<ISiteFields>): BrandedSiteProps {
  return {
    siteName: site.fields.name,
    topNav: site.fields.topNav.fields,
    footerNav: site.fields.footerNav.fields,
    logo: site.fields.logo,
    favicon: site.fields.favicon || null,
    domain: site.fields.id,
    googleTagManagerId: site.fields.googleTagManagerId || null,
    freshPaintToken: site.fields.freshPaintToken || null,
    gaMeasurementIdOrUATrackingId: site.fields.gaMeasurementIdOrUATrackingId || null,
    optimizelyProjectId: site.fields.optimizelyProjectId || null,
    socialMediaLink: site.fields.socialMediaLink || null,
    ecommIds: site.fields.ecommIds || null,
    cordialMetaData: site.fields.cordialMetaData || null,
    showLocationFilterFeature: site.fields.showLocationFilterFeature || null,
    profitCenter: site.fields.profitCenter || null,
    metaPhoneNumber:
      site.fields.metaPhoneNumber && site.fields.metaPhoneNumber.length > 0
        ? [site.fields.metaPhoneNumber[0]]
        : null,
    branding: {
      primaryColor: site.fields.brand.fields.primaryColor,
      secondaryColor: site.fields.brand.fields.secondaryColor,
      tertiaryColor: site.fields.brand.fields.tertiaryColor,
      highlightColor: site.fields.brand.fields.highlightColor,
      mutedColor: site.fields.brand.fields.mutedColor,
      neutralColor: site.fields.brand.fields.neutralColor,
      primaryTextColor: site.fields.brand.fields.primaryTextColor,
      secondaryTextColor: site.fields.brand.fields.secondaryTextColor,
      tertiaryTextColor: site.fields.brand.fields.tertiaryTextColor,
      highlightTextColor: site.fields.brand.fields.highlightTextColor,
      mutedTextColor: site.fields.brand.fields.mutedTextColor,
      neutralTextColor: site.fields.brand.fields.neutralTextColor,
    },
    reviewStatement: site.fields.reviewStatement || null,
    subscriptionLightbox: site.fields.subscriptionLightbox || null,
  };
}

export interface ErrorProps {
  error: {
    message: string;
    type?: string;
    httpCode: number;
    siteData?: BrandedSiteProps;
  };
}

function isError<P>(data: GetServerSidePropsResult<P | ErrorProps>): data is { props: ErrorProps } {
  return 'props' in data && 'error' in data.props;
}

function isNotFound<P>(data: GetServerSidePropsResult<P | ErrorProps>): data is { notFound: true } {
  return 'notFound' in data && data.notFound;
}

/**
 * Wraps an existing getServerSideProps function to perform error handling.  All exceptions
 * are caught and converted into a set of props that can be used to render the correct error
 * page.
 */
export function getServerSidePropsWithErrors<P>(
  fn: GetServerSideProps<P | ErrorProps>
): GetServerSideProps<P | ErrorProps> {
  return async (context) => {
    try {
      // the resolved url includes params from the dynamic routes
      // https://github.com/vercel/next.js/discussions/18662
      const newResolvedUrl = new URL(DUMMY_URL_BASE + context.resolvedUrl);
      Object.keys(context.params || {}).forEach((key) => {
        newResolvedUrl.searchParams.delete(key);
      });
      context.resolvedUrl = newResolvedUrl.pathname + newResolvedUrl.search;
      const result = await fn(context);

      if (isError(result)) {
        context.res.statusCode = result.props.error.httpCode;
      } else if (isNotFound(result)) {
        // convert the Next.JS { notFound: true } into an ErrorProps so it can be handled in _error.tsx
        return notFound();
      }

      return result;
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      context.res.statusCode = 500;
      return {
        props: {
          error: {
            message: error.message || 'Unknown Error',
            ...removeIfUndefined({ type: error.name }),
            httpCode: error.httpCode || null,
          },
        },
      };
    }
  };
}

export function toSlug(slug?: string[] | string, from = 0): string | undefined {
  return convertToArray(slug)?.slice(from).join('/');
}

function convertToArray<T>(item: T | T[]): T[] {
  return Array.isArray(item) ? item : [item];
}

export interface CustomRedirect {
  from: string;
  type: 'regex' | 'url';
  to: string;
}

function trimForwardSlashes(value: string): string {
  const trimmed = value;
  const startsWithSlash = value.charAt(0) === '/';
  const endsWithSlash = value.charAt(value.length - 1) === '/';
  if (startsWithSlash || endsWithSlash) {
    let trimmed = startsWithSlash ? value.substring(1, value.length) : value;
    trimmed = endsWithSlash ? trimmed.substring(0, trimmed.length - 1) : trimmed;
    return trimForwardSlashes(trimmed);
  }
  return trimmed;
}

function normalizeSlug(slug: string): string {
  return trimForwardSlashes(slug).toLowerCase();
}

export function getRedirect(resolvedUrl: string, redirects: CustomRedirect[]): string | undefined {
  const [slug, query] = resolvedUrl.split('?');

  const redirectTo = redirects.reduce((acc, redirect) => {
    if (acc) return acc;

    const [redirectSlug, redirectQuery] = redirect.from.split('?');
    if (
      redirect.type === 'url' &&
      normalizeSlug(slug) === normalizeSlug(redirectSlug) &&
      paramsContains(new URLSearchParams(redirectQuery), new URLSearchParams(query))
    ) {
      return redirect.to;
    } else if (redirect.type === 'regex') {
      const matcher = new RegExp(redirect.from);
      const matchResults = matcher.exec(slug);

      if (matchResults) {
        return matchResults.reduce((prev, curr, i) => {
          // first match is the full result, so we skip it
          return i === 0 ? prev : prev.replace(`$${i}`, curr);
        }, redirect.to);
      }
    }
  }, undefined as string | undefined);

  if (!redirectTo) return;

  const path = redirectTo.startsWith('http') ? redirectTo : `/${normalizeSlug(redirectTo)}`;

  return `${path}${query ? '?' + query : ''}`;
}

// Returns true if all the param key/value pairs in from are also in to
function paramsContains(from: URLSearchParams, to: URLSearchParams): boolean {
  return !Array.from(from.entries()).find(([key, value]) => !(to.get(key) === value));
}

interface UserCreds {
  username?: string | undefined;
  password?: string | undefined;
}

function rejectBasicAuth(res: ServerResponse): void {
  res.setHeader('WWW-Authenticate', 'Basic realm="Protected"');
  res.statusCode = 401;
  res.end('Unauthorized');
}

export function checkBasicAuth(
  res: ServerResponse,
  userCreds: UserCreds,
  authToken: string | undefined
): boolean {
  let isAuthenticated = true;

  if (userCreds.username === undefined || userCreds.password === undefined) return isAuthenticated;

  if (!authToken) {
    rejectBasicAuth(res);
    isAuthenticated = false;
  } else {
    const b64auth = authToken.split(' ')[1];
    const [user, password] = Buffer.from(b64auth, 'base64').toString().split(':');

    if (user !== userCreds.username || password !== userCreds.password) {
      rejectBasicAuth(res);
      isAuthenticated = false;
    }
  }
  return isAuthenticated;
}

export function firstValue(header: string | string[]): string;
export function firstValue(header: string | string[] | undefined): string | undefined;

export function firstValue(header: string | string[] | undefined): string | undefined {
  return Array.isArray(header) ? header[0] : header;
}

export function cacheDirective(preview: boolean | undefined): string {
  if (process.env.NODE_ENV === 'production' && !preview) {
    return `public, max-age=${process.env.NEXT_CACHE_MAX_AGE_SECS || 0}`;
  } else {
    return 'private, no-cache, no-store, max-age=0, must-revalidate';
  }
}

export type InvalidResponse =
  | { redirect: Redirect; revalidate?: number | boolean }
  | { props: ErrorProps };

interface Includes {
  Asset?: Asset[];
  Entry?: Entry<Record<string, unknown>>[];
}

export interface ContentfulPageData<T> {
  item: Entry<T>;
  includes?: Includes;
}

interface ContentfulPagesData<T> {
  items: Entry<T>[];
  includes?: Includes;
}

interface GetContentfulSiteDataProps {
  resolvedUrl: string;
  preview?: boolean;
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
  res: ServerResponse;
}

interface GetContentfulPageDataProps {
  preview?: boolean;
  res: ServerResponse;
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
  queryOptions: Record<string, string | number | boolean | undefined>;
  redirectInsteadofNotFound?: string;
}

export function isInvalid<T>(obj: T | InvalidResponse): obj is InvalidResponse {
  return 'redirect' in obj || 'notFound' in obj || ('props' in obj && 'error' in obj.props);
}

export function notFound(): InvalidResponse {
  return {
    props: {
      error: {
        message: 'Not Found',
        type: 'Not Found',
        httpCode: 404,
      },
    },
  };
}

export function withSiteData(
  invalid: InvalidResponse,
  siteData: BrandedSiteProps
): InvalidResponse {
  if ('props' in invalid) {
    invalid.props.error.siteData = siteData;
  }

  return invalid;
}

/**
 * Retrieves the site data based on the Host header.  If a site isn't found, return a 404.
 * This function also applies basic auth if a user/pass is configured for the site.  Lastly,
 * it will redirect if the site has a redirect defined for the requested URL.
 */
export async function getContentfulSiteData({
  preview,
  resolvedUrl,
  req: {
    headers: { host, authorization },
  },
  res,
}: GetContentfulSiteDataProps): Promise<BrandedSiteProps | InvalidResponse> {
  const client = cmsClient(preview);
  const sites = await client.getEntries<ISiteFields>({
    'fields.id': host || '',
    content_type: 'site',
    include: 10,
  });

  if (sites.items.length !== 1) {
    return notFound();
  }

  const site = sites.items[0];
  const brandedSiteData = getBrandedSiteData(site);

  const isAuthenticated = checkBasicAuth(res, site.fields, authorization);

  if (!isAuthenticated) {
    return notFound();
  }

  const redirectsEntry = site.fields.redirects;
  const redirects = (redirectsEntry?.fields.redirects as CustomRedirect[]) || [];
  const redirect = getRedirect(resolvedUrl, redirects);

  if (redirect) {
    return {
      redirect: {
        statusCode: 301,
        destination: redirect,
      },
    };
  }

  return brandedSiteData;
}

/**
 * Returns Contentful data intended to render a page.  The queryOptions are used to
 * query Contentful.  A 404 is returned if there are not exactly one result returned
 * for the query, unless exactlyOnePage is false.  The 404 is replaced with 302 redirect
 * if a redirectInsteadofNotFound slug is provided.
 */
export async function getContentfulPagesData<T>({
  preview,
  res,
  req: {
    headers: { host },
  },
  queryOptions,
  exactlyOnePage = false,
  redirectInsteadofNotFound,
  req,
}: GetContentfulPageDataProps & { exactlyOnePage?: boolean }): Promise<
  ContentfulPagesData<T> | InvalidResponse
> {
  const client = cmsClient(preview);

  const pages = await client.getEntries<T>(queryOptions);

  if (pages.items.length === 0 || (exactlyOnePage && pages.items.length !== 1)) {
    console.log(
      '404: ',
      JSON.stringify({
        host,
        pageCount: pages.items.length,
        queryOptions,
        reqData: {
          referer: req.headers.referer,
          userAgent: req.headers['user-agent'],
          ipAddress: req.headers['x-forwarded-for'],
        },
      })
    );

    if (redirectInsteadofNotFound) {
      // we don't do a permanent 301 redirect here otherwise the browsers will permanently cache this redirect
      // using s-maxage allows authors to still preview unpublished pages without fear of the browser storing the redirect.
      res.setHeader(
        'Cache-Control',
        `max-age=0, s-maxage=${process.env.NEXT_CACHE_MAX_AGE_SECS || 0}`
      );
      return {
        redirect: {
          statusCode: 302,
          destination: redirectInsteadofNotFound,
        },
      };
    }

    return notFound();
  }

  return {
    items: pages.items,
    includes: pages.includes,
  };
}

/**
 * Returns Contentful data intended to render a page.  It calls getContentfulPageData
 * with exactlyOnePage = true.
 */
export async function getContentfulPageData<T>(
  props: GetContentfulPageDataProps
): Promise<ContentfulPageData<T> | InvalidResponse> {
  const data = await getContentfulPagesData<T>({ ...props, exactlyOnePage: true });

  if (isInvalid(data)) {
    return data;
  }

  return {
    item: data.items[0],
    includes: data.includes,
  };
}

export function getAndSetCookie(
  req: IncomingMessage,
  res: ServerResponse,
  name: string,
  { expiresIn, value }: { expiresIn?: number; value?: string } = {}
): string {
  const cookies = new Cookies(req, res);

  if (!cookies.get(name)) {
    const cookieValue = value || uuid();
    cookies.set(name, cookieValue, { maxAge: expiresIn || 0 });
    return cookieValue;
  }

  return cookies.get(name) as string;
}

export function buildFullUrl(req: IncomingMessage, resolvedUrl: string): string {
  const protocol = firstValue(req.headers['cloudfront-forwarded-proto']) || 'http';
  const host = req.headers.host;
  return `${protocol}://${host}${resolvedUrl}`;
}
