import * as contentful from 'contentful';
import { Entry } from 'contentful';

export default function cmsClient(preview?: boolean): contentful.ContentfulClientApi {
  if (preview) {
    return contentful.createClient({
      removeUnresolved: true,
      space: (process || {}).env?.CONTENTFUL_SPACE || 'NA',
      environment: (process || {}).env?.CONTENTFUL_ENV || 'NA',
      host: 'preview.contentful.com',
      accessToken: (process || {}).env?.CONTENTFUL_PREVIEW_TOKEN || 'NA',
    });
  } else {
    return contentful.createClient({
      removeUnresolved: true,
      space: (process || {}).env?.CONTENTFUL_SPACE || 'NA',
      environment: (process || {}).env?.CONTENTFUL_ENV || 'NA',
      accessToken: (process || {}).env?.CONTENTFUL_ACCESS_TOKEN || 'NA',
    });
  }
}

export async function getAllEntries<T>(
  client: contentful.ContentfulClientApi,
  params: { [key: string]: string | number } = {},
  skip = 0,
  limit = 100
): Promise<Entry<T>[]> {
  const res = await client.getEntries<T>({ ...params, skip, limit });
  const data = res.items;
  if (res.total > res.skip + res.limit) {
    return data.concat(await getAllEntries<T>(client, params, skip + limit, limit));
  }
  return data;
}
