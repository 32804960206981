import Ajv, { ErrorObject } from 'ajv';
import schema from 'types/schema.json';

interface Schema {
  [key: string]: Record<string, unknown>;
}

/**
 * Validate the fields of a component against the JSON schema for the content type
 * to determine whether all validation constraints are met.  If errors are found,
 * return them.
 */
export function validateAgainstSchema(
  contentType: string,
  fields: unknown
): ErrorObject[] | undefined {
  const ajv = new Ajv({ useDefaults: true });
  const validate = ajv.compile((schema as Schema)[contentType]);
  validate(fields);
  if (validate.errors && validate.errors.length) {
    console.error('Preview Mode Validation Errors', validate.errors);

    return validate.errors;
  }
}
