import ssClient, {
  Breadcrumb,
  SortOption,
  SortDirection,
  ASCENDING,
  DESCENDING,
  SearchResponse,
  SearchFacet,
  SearchResult,
  VariantOptions,
} from './client';
import { ServerResponse, IncomingMessage } from 'http';
import { v4 as uuid } from 'uuid';
import { BadRequestError, getAndSetCookie } from '../../lib/routing';

const ONE_YEAR_IN_MILLISECONDS = 31556952000;

class SearchParameterError extends BadRequestError {
  constructor(message: string) {
    super(message);
    this.name = 'SearchParameterError';
  }
}

/**
 * Assists with flattening query parameters where each value could be undefined, one value or multiple
 * values. It accepts tuples of key/value as an array with two elements.
 */
function allPairs<K, V>([key, value]: [K, V | V[] | undefined]): [K, V][] {
  if (value) {
    if (Array.isArray(value)) {
      return value.map((aVal) => [key, aVal]);
    } else {
      return [[key, value]];
    }
  } else {
    return [];
  }
}

/**
 * Converts a Contentful filter (e.g. color=light grey) into a SearchSpring backgrond filter
 * (e.g. bgfilter.color=light%20grey)
 */
function formatBackgroundFilterQuery(keyValue: string): string {
  const [key, value] = keyValue.split('=', 2);

  return `&bgfilter.${key}=${value}`;
}

/**
 * Finds the sort query parameter in the page query (e.g. sort.brand=desc) and returns a tuple
 * of [field, direction] (e.g. ['brand', 'desc']).
 */
function sortComponents(query: NodeJS.Dict<string | string[]>): [string, string] | undefined {
  return Object.entries(query)
    .flatMap(allPairs)
    .find(([key]) => key.startsWith('sort.'));
}

/**
 * Generates a SearchSpring search URL containing pagination, fulltext search, field filtering and sorting.
 * the all components are derived from the passed query parameters except for the passed background
 * filters.
 */
export function searchUrl(
  query: NodeJS.Dict<string | string[]>,
  bgFilters: string[],
  domainUrl: string,
  type: 'search' | 'autocomplete' | 'finder' = 'search',
  resultsPerPage = 30
): string {
  let page = Array.isArray(query.page) ? query.page[0] : query.page;
  if (page) {
    // try to remove all non page digits, to handle unintending non digits in the page qs
    // ex. google bot is adding a trailing forward slash so we now check for one and remove it
    page = page.replace(/\D/g, '');
    if (!Number.isInteger(Number(page))) {
      throw new SearchParameterError(`page parameter, ${page}, isn't an integer`);
    }
  }
  const pageStr = page ? `&page=${page}` : '';

  const filtersStr = Object.entries(query)
    .filter(([key]) => key.startsWith('filter.'))
    .flatMap(allPairs)
    .map(([key, value]) => `&${key}=${value}`)
    .join('');

  const bgFiltersStr = bgFilters.map((filter) => formatBackgroundFilterQuery(filter)).join('');

  const sortComps = sortComponents(query);
  if (sortComps) {
    if (!new Set([ASCENDING, DESCENDING]).has(sortComps[1])) {
      throw new SearchParameterError(
        `Sort direction of ${sortComps[1]} not supported, use ${ASCENDING} or ${DESCENDING}`
      );
    }
  }
  const sortStr = sortComps ? `&${sortComps[0]}=${sortComps[1]}` : '';

  const queryStr = query.q ? `&q=${query.q}` : '';
  const siteId = query.siteId ? `siteId=${query.siteId}` : null;

  if (!siteId) {
    throw new Error('missing site id');
  }

  // https://searchspring.zendesk.com/hc/en-us/articles/115000442606
  const domainStr = domainUrl ? `&domain=${domainUrl}` : '';

  return encodeURI(
    `/api/search/${type}.json?${siteId}&resultsFormat=native&resultsPerPage=${resultsPerPage}${pageStr}${sortStr}${queryStr}${filtersStr}${bgFiltersStr}${domainStr}`
  );
}

export interface SearchSummary {
  filters: Breadcrumb[];
  results: SearchResultWithVariants[];
  facets: SearchFacet[];
  sortOptions: SortOption[];
  pageCount: number;
  currentPage: number;
  totalResults: number;
}

export type SearchResultWithVariants = SearchResult & {
  variants: { [key: string]: VariantOptions };
};

export interface SearchHeaders {
  sessionId: string;
  userId: string;
  ipAddress: string;
  userAgent: string;
}

/**
 * Perform a SearchSpring search with the provided URL and session identifier.
 */
export async function search(
  url: string,
  siteId: string,
  { sessionId, userId, ipAddress, userAgent }: SearchHeaders
): Promise<SearchSummary> {
  const client = ssClient(siteId);
  const searchResults = await client.get<SearchResponse>(url, {
    headers: {
      'searchspring-session-id': sessionId,
      'searchspring-user-id': userId,
      'searchspring-page-load-id': uuid(),
      // https://searchspring.zendesk.com/hc/en-us/articles/115000442606
      REMOTE_ADDR: ipAddress,
      HTTP_X_FORWARDED_FOR: ipAddress,
      'User-Agent': userAgent,
    },
  });

  const resultsWithVariants = searchResults.data.results.map((result) => ({
    variants: computeVariants(result),
    ...result,
  }));

  return {
    filters: searchResults.data.breadcrumbs,
    results: resultsWithVariants,
    facets: searchResults.data.facets,
    sortOptions: searchResults.data.sorting.options,
    pageCount: searchResults.data.pagination.totalPages,
    currentPage: searchResults.data.pagination.currentPage,
    totalResults: searchResults.data.pagination.totalResults,
  };
}

/**
 * SearchSpring returns the variants as serialized JSON.  This function deserializes
 * and groups the option values by variant SKU
 */
function computeVariants(result: SearchResult): { [key: string]: VariantOptions } {
  const skuOptions: VariantOptions[] = JSON.parse(
    '[' + result.children.replace(/&quot;/g, '"') + ']'
  );

  return skuOptions
    .filter((option) => option.facet_match !== false)
    .reduce<{ [key: string]: VariantOptions }>((acc, option) => {
      acc[option.sku] = option;
      return acc;
    }, {});
}

/**
 * Compute the currently selected sort and available sort options.  Two options are
 * conditionally added to the sort options returned from SearchSpring:
 * 1.) Custom Sort - Returned when the page sort doesn't match any of the options returned
 * by SearchSpring.  This option is never returned in the sort options but may be returned
 * as the current sort.
 */
export function sortSummary(
  origSortOptions: SortOption[],
  query: NodeJS.Dict<string | string[]>
): { currentSort: SortOption; sortOptions: SortOption[] } {
  const sortComps = sortComponents(query);
  const currentSort = sortComps
    ? origSortOptions.find(
        (option) =>
          sortComps[0].slice('sort.'.length) === option.field && sortComps[1] === option.direction
      ) || {
        label: 'Custom Sort',
        field: sortComps[0].slice('sort.'.length),
        direction: sortComps[1] as SortDirection,
      }
    : origSortOptions[0];

  return { currentSort, sortOptions: origSortOptions };
}

export function generateSSCookiesAndHeaders(
  req: IncomingMessage,
  res: ServerResponse
): SearchHeaders {
  const sessionId = getAndSetCookie(req, res, 'ssSessionIdNamespace');
  const userId = getAndSetCookie(req, res, 'ssUserId', { expiresIn: ONE_YEAR_IN_MILLISECONDS });
  // https://searchspring.zendesk.com/hc/en-us/articles/201185129-Adding-IntelliSuggest-Tracking#productclicks
  // we require both ssUserId and _isuid as per James Bathgate It’s a legacy thing between our normal reporting system and our recommendations system.
  getAndSetCookie(req, res, '_isuid', { expiresIn: ONE_YEAR_IN_MILLISECONDS, value: userId });

  const ipAddress = ((req.headers['x-forwarded-for'] || '') as string).split(',')[0];
  const userAgent = req.headers['user-agent'] || '';

  return {
    sessionId,
    userId,
    ipAddress,
    userAgent,
  };
}
