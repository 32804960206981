import { AxiosInstance } from 'axios';
import { env } from 'lib/util';
import querystring from 'querystring';
import { IFragmentReviewInc } from 'types/contentful';
import { Document, BLOCKS } from '@contentful/rich-text-types/';
import moment from 'moment';

/* The API accepts both branch ids and branch group ids but providing both
   causes the branch group ids to be ignored.  Therefore we only allow
   one or the other */
export type ReviewRequest = { branchIds: number[] } | { branchGroupIds: number[] };

interface ApiReview {
  BranchId: number;
  RatingPlaceUrl: string;
  RatingFiveStar: number;
  RatingName: string;
  RatingNameTestimonials: string;
  RatingTitle: string;
  RatingComment: string;
  RatingSource: string;
}

export interface IReviewFragment {
  fields: {
    id: string;
    testimonial: Document;
    reviewer: string;
    reviewerShortname: string;
    reviewerLocation: string;
    fiveStar?: number | null;
  };
}
export interface Review {
  branchId: number;
  placeUrl: string;
  fiveStar: number;
  name: string;
  shortName: string;
  title: string;
  comment: string;
  source: string;
}
interface ApiReviewAverage {
  BranchId: number;
  PublicReviewFiveStarAvg: number;
  PublicReviewCount: number;
  PublicReviewCount5: number;
  PublicReviewCount4: number;
  PublicReviewCount3: number;
  PublicReviewCount2: number;
  PublicReviewCount1: number;
  PublicReviewCount0: number;
}

export interface ReviewAverage {
  count: number;
  fiveStarAvg: number;
}

function requestParameters(request: ReviewRequest): string {
  const requestAsParams =
    'branchIds' in request
      ? {
          branchIds: request.branchIds.join(','),
        }
      : {
          branchGroupIds: request.branchGroupIds.join(','),
        };

  // only retrieve last 18 months
  const start = moment().subtract(18, 'months');

  return querystring.stringify({
    ...requestAsParams,
    corporateId: env('REVIEWINC_CORPORATE_ID'),
    apiKey: env('REVIEWINC_API_KEY'),
    productionMode: true,
    testimonialsOnly: true,
    startYear: start.year(),
    startMonth: start.month() + 1,
    startDay: start.date(),
  });
}

export async function getReviewsWithComments(
  client: AxiosInstance,
  request: ReviewRequest
): Promise<Review[]> {
  return (await getReviews(client, request)).filter((review) => review.comment.length > 0);
}

export async function getReviews(client: AxiosInstance, request: ReviewRequest): Promise<Review[]> {
  return (
    await client.post<ApiReview[]>('/GetPublicReviews.aspx', requestParameters(request))
  ).data.map((review) => ({
    branchId: review.BranchId,
    placeUrl: review.RatingPlaceUrl,
    fiveStar: review.RatingFiveStar,
    name: review.RatingName,
    shortName: review.RatingNameTestimonials,
    title: review.RatingTitle,
    comment: review.RatingComment,
    source: review.RatingSource,
  }));
}

/**
 * Review Inc returns a separate average score for each branch that is returned
 * by the query.  To compute the average rating across all branches, we need
 * to add up the individual 2-star, 3-star, etc. values.
 */
export async function getReviewAverage(
  client: AxiosInstance,
  request: ReviewRequest
): Promise<ReviewAverage> {
  const averages = (
    await client.post<ApiReviewAverage[]>('/GetBranches.aspx', requestParameters(request))
  ).data.reduce(
    (acc, avg) => ({
      total: acc.total + avg.PublicReviewCount,
      numStars:
        acc.numStars +
        avg.PublicReviewCount1 +
        avg.PublicReviewCount2 * 2 +
        avg.PublicReviewCount3 * 3 +
        avg.PublicReviewCount4 * 4 +
        avg.PublicReviewCount5 * 5,
    }),
    { total: 0, numStars: 0 }
  );

  return {
    count: averages.total,
    fiveStarAvg: Math.round((averages.numStars / averages.total + Number.EPSILON) * 100) / 100,
  };
}

export function reviewFragmentToRequest(reviewFragment: IFragmentReviewInc): ReviewRequest {
  if (reviewFragment.fields.type == 'Branch Id') {
    return { branchIds: reviewFragment.fields.ids.map((id) => parseInt(id)) };
  } else {
    return { branchGroupIds: reviewFragment.fields.ids.map((id) => parseInt(id)) };
  }
}

export function reviewToTestimoial(review: Review): IReviewFragment {
  const document: Document = {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [
      {
        nodeType: BLOCKS.PARAGRAPH,
        data: {},
        content: [
          {
            nodeType: 'text',
            value: review.comment,
            data: {},
            marks: [],
          },
        ],
      },
    ],
  };

  const testimonial: IReviewFragment = {
    fields: {
      id: review.branchId.toString(),
      testimonial: document,
      reviewer: review.name,
      reviewerShortname: review.shortName,
      reviewerLocation: review.source,
      fiveStar: review.fiveStar,
    },
  };

  return testimonial;
}
