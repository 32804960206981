import { AxiosInstance } from 'axios';
import { removeIfUndefined } from 'lib/util';

interface JobsResponse {
  jobs: GreenhouseJob[];
}

interface GreenhouseJob {
  id: number;
  title: string;
  content: string;
  updated_at: string;
  location: {
    name: string;
  };
  metadata: [
    {
      name: string;
      value: string;
    }
  ];
  departments: [
    {
      name: string;
    }
  ];
}

export interface Job {
  id: number;
  title: string;
  updated_at: string;
  location: string;
  state: string;
  department: string;
  brand?: string;
}

export type JobWithContent = Job & {
  content: string;
};

export interface Board {
  id: string;
  name: string;
}

export async function getJobList(
  client: AxiosInstance,
  boardId: string,
  brand?: string
): Promise<Job[]> {
  return (await client.get<JobsResponse>(`/${boardId}/jobs?content=true`)).data.jobs
    .map(toJob)
    .filter((job) => !brand || brand === job.brand);
}

export async function getBoard(client: AxiosInstance, boardId: string): Promise<Board> {
  return { id: boardId, ...(await client.get<{ name: string }>(`/${boardId}`)).data };
}

export async function getJob(
  client: AxiosInstance,
  boardId: string,
  jobId: string
): Promise<JobWithContent> {
  return toJobWithContent((await client.get<GreenhouseJob>(`/${boardId}/jobs/${jobId}`)).data);
}

function toJob({ id, title, updated_at, location, metadata, departments }: GreenhouseJob): Job {
  return {
    id,
    title,
    location: location.name,
    updated_at,
    state: metadata.find(({ name }) => name === 'State')?.value || '',
    ...removeIfUndefined({ brand: metadata.find(({ name }) => name === 'Brand')?.value || '' }),
    department: departments[0].name.trim(),
  };
}

function toJobWithContent(job: GreenhouseJob): JobWithContent {
  return {
    ...toJob(job),
    content: job.content,
  };
}
