import axios from 'axios';

/**
 * Review Inc doesn't use the Content-Type header or the HTTP status code
 * correctly.  So we have to attempt to parse it into JSON and if it fails
 * it's a text error response.
 */
function tryParse<T>(unparsed: string): T {
  try {
    const data: T = JSON.parse(unparsed);
    return data;
  } catch (err) {
    const [, message] = unparsed.split(' : ', 2);
    throw new Error(message);
  }
}

export default axios.create({
  baseURL: `https://nr4.me`,
  responseType: 'text',
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
  transformResponse: (data) => tryParse(data),
});
