import { PlaceType } from 'components/UI/NewLocationAutocomplete';

export async function getPlace(
  placeId: string,
  freshPaintToken: string
): Promise<PlaceType | null> {
  if (!placeId || !freshPaintToken) {
    return null;
  }

  const url = `https://freshpaint-hipaa-maps.com/places/v0/indexes/freshpaint/places/${placeId}?token=${freshPaintToken}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();

  if (data?.Place) {
    return {
      place_id: data.Place.PlaceId ?? '',
      description: data.Place.Label ?? '',
      structured_formatting: {
        main_text: data.Place.Label ?? '',
        secondary_text: '',
        main_text_matched_substrings: [{ offset: 0, length: (data.Place.Label ?? '').length }],
      },
      lat: data.Place.Geometry.Point[1] ?? 0,
      lng: data.Place.Geometry.Point[0] ?? 0,
    };
  }

  return null;
}
